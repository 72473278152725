// AboutMe.js

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import styles from './About.module.css'; // Import CSS module for styling
import Slider from "../../components/slider/slider";
import { Link } from 'react-router-dom';

const AboutMe = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
         <>
           <div className='container'>
           <div className={styles.aboutDis}>
           <div data-aos="flip-left" className={styles.animationDiv}>
            <div className={styles.aboutContent}>
                <h2>H E L L O</h2>
                <span>I'M REALLY HAPPY YOU'RE HERE
                </span>
                <p>I'm Medi Rushiti, a professional photographer with almost a decade of experience. I have dedicated myself to capturing moments that tell a story, evoke emotion, and create lasting memories. Photography is more than just a career for me—it's my passion, and it drives me to constantly explore new perspectives and techniques.


             

          </p>
<p>
From corporate events to joyful wedding occasions, intimate portraits to large gatherings, I have had the privilege of capturing some of life’s most precious moments and experience the process of brining them into life through my lens. These experiences have shaped my approach and sharpened my skills and honed my ability to work hard, inspiring me to consistently elevate my craft and offer my clients unforgettable images that reflect authenticity and artistry.
</p>
            </div>
            </div>
            <div data-aos="flip-right" className={styles.animationDiv}>
            <div>
                <img src='/images/profile.jpg'/>
                </div>
                </div>
       </div>
     
       {/* <div className={`${styles.aboutDis} ${styles.aboutDisRev}`}>
       <div data-aos="flip-right" className={styles.animationDiv}>
            <div className={styles.aboutContent}>
                <h2>About the images
                </h2>
               
                <p>Images don’t just happen, they’re crafted. Whether it’s being in-the-moment to capture that perfect candid, or saying just the right thing to evoke the ideal reaction, my years of experience have taught me how to navigate all the different aspects of a wedding day so I never miss a moment. </p>
                <p>When a bride is getting ready, I’ll sometimes clear the room to help her feel more comfortable. We’ll talk about the couple’s history, a story that makes her smile every time she thinks of him. Their first kiss, the first time they held hands and the overwhelming emotion that came with his proposal. As she opens up about their history, I quietly shoot to get the most authentic smile and joy possible.</p>
                <p>When you fly half-way across the world to exchange your vows in the hills of Tuscany and it rains the entire day, I promise I won’t miss the 15 seconds where the sun peeks out. We’ll chase the light. We’ll make the most of it. And I’ll work harder than you can imagine to capture magical images of your whole celebration, rain or shine.</p>
          <div className={` ${styles.aboutMe}`}>
        
        <Link to="/contact">CONNECT WITH ME</Link>
      </div>

            </div>
            </div>
            <div data-aos="flip-left" className={styles.animationDiv}>
            <div>
                <img src='/images/modeling/model34.webp'/>
                </div>
                </div>
       </div> */}

         </div>
         
       <Slider />
         </>
  );
}

export default AboutMe;
